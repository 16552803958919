import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import headerStyles from "./header.module.scss"

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.overlay}></div>
      <div className={headerStyles.heroContent}>
        <p className={headerStyles.brand}>
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </p>
        <p className={headerStyles.description}>
          {data.site.siteMetadata.description}
        </p>
      </div>
      <nav className={headerStyles.navContainer}>
        <ul className={headerStyles.navList}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link
              to="/cleanup-events/"
              activeClassName={headerStyles.activeMenuItem}
            >
              Cleanup Events
            </Link>
          </li>
          <li>
            <Link
              to="/photo-gallery/"
              activeClassName={headerStyles.activeMenuItem}
            >
              Photo Gallery
            </Link>
          </li>
          <li>
            <Link
              to="/beach-news/"
              activeClassName={headerStyles.activeMenuItem}
            >
              Beach News
            </Link>
          </li>
          <li>
            <Link
              to="/our-story/"
              activeClassName={headerStyles.activeMenuItem}
            >
              Our Story
            </Link>
          </li>
          <li>
            <Link
              to="/sponsorship/"
              activeClassName={headerStyles.activeMenuItem}
            >
              Sponsorship
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header